import React, { useContext } from "react"
import { Field } from "formik"
import { HousingApplicationFormContext } from "../form"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"

const FieldGroupOthersInHousehold = () => {
  const { values, setFieldValue, errors, touched } = useContext(
    HousingApplicationFormContext
  )

  return (
    <>
      <div className="form--ha__row form--ha__row--tight">
        <h4 className="form--ha__sub-heading">Anyone else in your household</h4>

        <div className="form__group form__group--inline form--ha__group--title">
          <label className="form__label" htmlFor="household_others_first_title">
            Title
          </label>
          <Field
            id="title"
            component="select"
            name="household_others_first_title"
            className="form__select form__select--title"
          >
            <option value="no-value" disabled>
              --
            </option>
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Miss">Miss</option>
            <option value="Ms">Ms</option>
            <option value="Dr">Dr</option>
          </Field>
          {errors.household_others_first_title &&
          touched.household_others_first_title ? (
            <p className="form__error">{errors.household_others_first_title}</p>
          ) : null}
        </div>
        <div className="form__group form__group--inline form--ha__group--first-name">
          <label
            className="form__label"
            htmlFor="household_others_first_first_name"
          >
            <span>Others in household 1 - </span>First Name
          </label>
          <Field
            type="text"
            className="form__text"
            name="household_others_first_first_name"
            id="household_others_first_first_name"
          />
          {errors.household_others_first_first_name &&
          touched.household_others_first_first_name ? (
            <p className="form__error">
              {errors.household_others_first_first_name}
            </p>
          ) : null}
        </div>
        <div className="form__group form__group--inline form--ha__group--surname">
          <label
            className="form__label"
            htmlFor="household_others_first_surname"
          >
            <span>Others in household 1 - </span>Surname
          </label>
          <Field
            type="text"
            className="form__text"
            name="household_others_first_surname"
            id="household_others_first_surname"
          />
          {errors.household_others_first_surname &&
          touched.household_others_first_surname ? (
            <p className="form__error">
              {errors.household_others_first_surname}
            </p>
          ) : null}
        </div>

        <div className="form__group form__group--inline form--ha__group--dob">
          <label className="form__label" htmlFor="household_others_first_dob">
            <span>Others in household 1 - </span>Date of Birth
          </label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="household_others_first_dob"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              value={values.household_others_first_dob}
              onChange={(value) =>
                setFieldValue("household_others_first_dob", value)
              }
              KeyboardButtonProps={{
                "aria-label": "Input their date of birth",
              }}
              openTo="year"
            />
          </MuiPickersUtilsProvider>
          {errors.household_others_first_dob &&
          touched.household_others_first_dob ? (
            <p className="form__error">{errors.household_others_first_dob}</p>
          ) : null}
        </div>
        <div className="form__group form__group--inline form--ha__group--relationship">
          <label
            className="form__label"
            htmlFor="household_others_first_relationship"
          >
            <span>Others in household 1 - </span>Relationship to first applicant
          </label>
          <Field
            type="text"
            className="form__text"
            name="household_others_first_relationship"
            id="household_others_first_relationship"
          />
          {errors.household_others_first_relationship &&
          touched.household_others_first_relationship ? (
            <p className="form__error">
              {errors.household_others_first_relationship}
            </p>
          ) : null}
        </div>
        <div className="form__group form__group--inline form--ha__group--ni-number">
          <label
            className="form__label"
            htmlFor="household_others_first_ni_number"
          >
            <span>Others in household 1 - </span>NI Number
          </label>
          <Field
            type="text"
            className="form__text"
            name="household_others_first_ni_number"
            id="household_others_first_ni_number"
          />
          {errors.household_others_first_ni_number &&
          touched.household_others_first_ni_number ? (
            <p className="form__error">
              {errors.household_others_first_ni_number}
            </p>
          ) : null}
        </div>
      </div>
      <hr className="form--ha__divider" />
      <div className="form--ha__row">
        <div className="form__group form__group--inline form--ha__group--title">
          <label
            className="form__label"
            htmlFor="household_others_second_title"
          >
            <span>Others in household 2 - </span>Title
          </label>
          <Field
            id="title"
            component="select"
            name="household_others_second_title"
            className="form__select form__select--title"
          >
            <option value="Select">--</option>
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Miss">Miss</option>
            <option value="Ms">Ms</option>
            <option value="Dr">Dr</option>
          </Field>
          {errors.household_others_second_title &&
          touched.household_others_second_title ? (
            <p className="form__error">
              {errors.household_others_second_title}
            </p>
          ) : null}
        </div>
        <div className="form__group form__group--inline form--ha__group--first-name">
          <label
            className="form__label"
            htmlFor="household_others_second_first_name"
          >
            <span>Others in household 2 - </span>First Name
          </label>
          <Field
            type="text"
            className="form__text"
            name="household_others_second_first_name"
            id="household_others_second_first_name"
          />
          {errors.household_others_second_first_name &&
          touched.household_others_second_first_name ? (
            <p className="form__error">
              {errors.household_others_second_first_name}
            </p>
          ) : null}
        </div>
        <div className="form__group form__group--inline form--ha__group--surname">
          <label
            className="form__label"
            htmlFor="household_others_second_surname"
          >
            <span>Others in household 2 - </span>Surname
          </label>
          <Field
            type="text"
            className="form__text"
            name="household_others_second_surname"
            id="household_others_second_surname"
          />
          {errors.household_others_second_surname &&
          touched.household_others_second_surname ? (
            <p className="form__error">
              {errors.household_others_second_surname}
            </p>
          ) : null}
        </div>

        <div className="form__group form__group--inline form--ha__group--dob">
          <label className="form__label" htmlFor="household_others_second_dob">
            <span>Others in household 2 - </span>Date of Birth
          </label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="household_others_second_dob"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              value={values.household_others_second_dob}
              onChange={(value) =>
                setFieldValue("household_others_second_dob", value)
              }
              KeyboardButtonProps={{
                "aria-label": "Input their date of birth",
              }}
              openTo="year"
            />
          </MuiPickersUtilsProvider>
          {errors.household_others_second_dob &&
          touched.household_others_second_dob ? (
            <p className="form__error">{errors.household_others_second_dob}</p>
          ) : null}
        </div>
        <div className="form__group form__group--inline form--ha__group--relationship">
          <label
            className="form__label"
            htmlFor="household_others_second_relationship"
          >
            <span>Others in household 2 - </span>Relationship to first applicant
          </label>
          <Field
            type="text"
            className="form__text"
            name="household_others_second_relationship"
            id="household_others_second_relationship"
          />
          {errors.household_others_second_relationship &&
          touched.household_others_second_relationship ? (
            <p className="form__error">
              {errors.household_others_second_relationship}
            </p>
          ) : null}
        </div>
        <div className="form__group form__group--inline form--ha__group--ni-number">
          <label
            className="form__label"
            htmlFor="household_others_second_ni_number"
          >
            <span>Others in household 2 - </span>NI Number
          </label>
          <Field
            type="text"
            className="form__text"
            name="household_others_second_ni_number"
            id="household_others_second_ni_number"
          />
          {errors.household_others_second_ni_number &&
          touched.household_others_second_ni_number ? (
            <p className="form__error">
              {errors.household_others_second_ni_number}
            </p>
          ) : null}
        </div>
      </div>
    </>
  )
}
export default FieldGroupOthersInHousehold
