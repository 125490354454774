import React, { useContext } from "react"
import { Field } from "formik"
import { HousingApplicationFormContext } from "../form"

const FieldGroupExistingResident = () => {
  const { errors, touched } = useContext(HousingApplicationFormContext)
  return (
    <fieldset className="form__group">
      <legend className="form__label">
        Are you an existing PRHA resident?
      </legend>
      <div className="form--ha__radio form--ha__radio--inline">
        <label>
          <Field
            type="radio"
            name="existing_resident"
            value="I am an existing resident"
          />
          Yes</label>
      </div>
      <div className="form--ha__radio form--ha__radio--inline">
        <label>
          <Field
            type="radio"
            name="existing_resident"
            value="I am not an existing resident"
          />
          No</label>
      </div>
      {errors.existing_resident && touched.existing_resident ? (
        <p className="form__error">{errors.existing_resident}</p>
      ) : null}
    </fieldset>
  )
}

export default FieldGroupExistingResident
