import React, { useContext } from "react"
import { Field } from "formik"
import { HousingApplicationFormContext } from "../form"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"

const FieldGroupYourHousehold = () => {
  const { values, setFieldValue, errors, touched } = useContext(
    HousingApplicationFormContext
  )

  return (
    <div className="form--ha__row form--ha__row--tight">
      <h3 className="section__title form--ha__section-title">
        <span>Your household</span>
      </h3>
      <div className="form--ha__section-content">
        <h4 className="form--ha__sub-heading">1. Your personal details</h4>
        <div className="form__group form__group--inline form--ha__group--title">
          <label className="form__label" htmlFor="personal_household_title">
            Title
          </label>
          <Field
            id="title"
            component="select"
            name="personal_household_title"
            className="form__select form__select--title"
          >
            <option value="no-value" disabled>
              --
            </option>
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Miss">Miss</option>
            <option value="Ms">Ms</option>
            <option value="Dr">Dr</option>
          </Field>
          {errors.personal_household_title &&
          touched.personal_household_title ? (
            <p className="form__error">{errors.personal_household_title}</p>
          ) : null}
        </div>
        <div className="form__group form__group--inline form--ha__group--first-name">
          <label
            className="form__label"
            htmlFor="personal_household_first_name"
          >
            First Name
          </label>
          <Field
            type="text"
            className="form__text"
            name="personal_household_first_name"
            id="personal_household_first_name"
          />
          {errors.personal_household_first_name &&
          touched.personal_household_first_name ? (
            <p className="form__error">
              {errors.personal_household_first_name}
            </p>
          ) : null}
        </div>
        <div className="form__group form__group--inline form--ha__group--surname">
          <label className="form__label" htmlFor="personal_household_surname">
            Surname
          </label>
          <Field
            type="text"
            className="form__text"
            name="personal_household_surname"
            id="personal_household_surname"
          />
          {errors.personal_household_surname &&
          touched.personal_household_surname ? (
            <p className="form__error">{errors.personal_household_surname}</p>
          ) : null}
        </div>

        <div className="form__group form__group--inline form--ha__group--dob">
          <label className="form__label" htmlFor="personal_household_dob">
            Date of Birth
          </label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="personal_household_dob"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              value={values.personal_household_dob}
              onChange={(dob) => setFieldValue("personal_household_dob", dob)}
              KeyboardButtonProps={{
                "aria-label": "Input your date of birth",
              }}
              openTo="year"
            />
          </MuiPickersUtilsProvider>
          {errors.personal_household_dob && touched.personal_household_dob ? (
            <p className="form__error">{errors.personal_household_dob}</p>
          ) : null}
        </div>
        <div className="form__group form__group--inline form--ha__group--ni-number">
          <label className="form__label" htmlFor="personal_household_ni_number">
            NI Number
          </label>
          <Field
            type="text"
            className="form__text"
            name="personal_household_ni_number"
            id="personal_household_ni_number"
          />
          {errors.personal_household_ni_number &&
          touched.personal_household_ni_number ? (
            <p className="form__error">{errors.personal_household_ni_number}</p>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default FieldGroupYourHousehold
