import React, { useContext } from "react"
import { Field } from "formik"
import { HousingApplicationFormContext } from "../form"

const FieldGroupYourHousingHistoryA = () => {
  const { values, errors, touched } = useContext(HousingApplicationFormContext)

  return (
    <div className="form--ha__row form--ha__row--tight">
      <h3 className="section__title">
        <span>About your housing history</span>
      </h3>
      <div className="form__group form--ha__group--inputs-inline">
        <label className="form__label">
          7. How long have you lived at your current address?
        </label>
        <div className="form__text form--ha__text--years">
          <label htmlFor="current_property_length_years">Years</label>
          <Field
            id="current_property_length_years"
            type="number"
            name="current_property_length_years"
          />
        </div>
        <div className="form__text form--ha__text--months">
          <label htmlFor="current_property_length_months">Months</label>
          <Field
            id="current_property_length_months"
            type="number"
            name="current_property_length_months"
          />
        </div>
        {errors.current_length_months && touched.current_length_months ? (
          <p className="form__error">{errors.current_length_months}</p>
        ) : null}
      </div>
      <div className="form__group">
        <label
          className="form__label"
          htmlFor="current_property_length_address"
        >
          If you have lived at your current address for less than 12 months,
          please provide your previous address in full and your previous
          landlord’s contact address and telephone number.
        </label>
        <Field
          id="current_property_length_address"
          component="textarea"
          name="current_property_length_address"
          className="form__textarea"
        />
        {errors.current_property_length_address &&
        touched.current_property_length_address ? (
          <p className="form__error">
            {errors.current_property_length_address}
          </p>
        ) : null}
      </div>

      <hr className="form--ha__divider" />

      <div className="form__group form__group--inputs-inline">
        <label className="form__label">
          8. If you rent your current home, please tell us the name and address
          of your landlord. Please note that PRHA may approach your current
          landlord for a reference:
        </label>
        <Field
          id="landlord_address"
          component="textarea"
          name="landlord_address"
          className="form__textarea"
        />
        {errors.landlord_address && touched.landlord_address ? (
          <p className="form__error">{errors.landlord_address}</p>
        ) : null}
      </div>

      <hr className="form--ha__divider" />

      <fieldset className="form__group">
        <legend className="form__label" htmlFor="arrears">
          9. As far as you are aware, do you owe rent arrears to a current or
          former landlord or do you owe mortgage arrears?
        </legend>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field type="radio" name="arrears" value="Yes I owe rent arrears" />
            Yes
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="arrears"
              value="No I do not owe rent arrears"
            />
            No
          </label>
        </div>
        {errors.arrears && touched.arrears ? (
          <p className="form__error">{errors.arrears}</p>
        ) : null}
      </fieldset>

      <hr className="form--ha__divider" />

      <fieldset className="form__group">
        <legend className="form__label" htmlFor="arrears_clearing">
          10. Have you made any arrangements to clear these arrears?
        </legend>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="arrears_clearing"
              value="Yes I have made arrangements to clear these arrears"
            />
            Yes
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="arrears_clearing"
              value="No I have not made arrangements to clear these arrears"
            />
            No
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field type="radio" name="arrears_clearing" value="N/A" />
            N/A
          </label>
        </div>
        {errors.arrears_clearing && touched.arrears_clearing ? (
          <p className="form__error">{errors.arrears_clearing}</p>
        ) : null}
      </fieldset>
      {values.arrears_clearing ===
        "Yes I have made arrangements to clear these arrears" && (
        <div className="form__group">
          <label className="form__label">If yes please provide details:</label>
          <Field
            id="arrears_clearing_details"
            component="textarea"
            name="arrears_clearing_details"
            className="form__textarea"
          />
          {errors.arrears_clearing_details &&
          touched.arrears_clearing_details ? (
            <p className="form__error">{errors.arrears_clearing_details}</p>
          ) : null}
        </div>
      )}

      <hr className="form--ha__divider" />
    </div>
  )
}

export default FieldGroupYourHousingHistoryA
