import React, { useContext } from "react"
import { Field } from "formik"
import { HousingApplicationFormContext } from "../form"

const FieldGroupAccomodationRequired = () => {
  const { values, errors, touched } = useContext(HousingApplicationFormContext)

  return (
    <div className="form--ha__row form--ha__row--tight">
      <h3 className="section__title">
        <span>Accommodation required</span>
      </h3>
      <fieldset className="form__group">
        <legend className="form__label" htmlFor="accommodation_required_type">
          17. What type of accommodation do you wish to be considered for?
        </legend>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="accommodation_required_type"
              value="Any"
            />
            Any
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="accommodation_required_type"
              value="Flat"
            />
            Flat
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="accommodation_required_type"
              value="Bungalow"
            />
            Bungalow
          </label>
        </div>
        {errors.accommodation_required_type &&
        touched.accommodation_required_type ? (
          <p className="form__error">{errors.accommodation_required_type}</p>
        ) : null}
      </fieldset>

      <hr className="form--ha__divider" />

      <div className="form__group">
        <label
          className="form__label"
          htmlFor="accommodation_required_floor_level"
        >
          18. Please indicate which floor level you would accept accommodation
          on.
        </label>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="accommodation_required_floor_level"
              value="Ground"
            />
            Ground
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="accommodation_required_floor_level"
              value="First"
            />
            First
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="accommodation_required_floor_level"
              value="Second"
            />
            Second
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="accommodation_required_floor_level"
              value="Third"
            />
            Third
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="accommodation_required_floor_level"
              value="Any floor with a lift"
            />
            Any floor with a lift
          </label>
        </div>
        {errors.accommodation_required_floor_level &&
        touched.accommodation_required_floor_level ? (
          <p className="form__error">
            {errors.accommodation_required_floor_level}
          </p>
        ) : null}
      </div>

      <hr className="form--ha__divider" />

      <div className="form__group">
        <p>
          We will automatically register single people for studio / one bedroom
          accommodation, unless requested otherwise.
        </p>
        <fieldset role="group">
          <legend
            id="accommodation_required_type_label"
            className="form__label form--ha__label--space"
          >
            19. Please confirm the type of accommodation you wish to be
            considered for below. <br />A studio has one main living space with
            separate kitchen and shower room. <br />
            Tick all that apply:
          </legend>
          <div className="form--ha__checkbox-container form--ha__checkbox-container--inline">
            <label className="form--ha__label--checkbox">
              <Field
                type="checkbox"
                className="form--ha__checkbox"
                name="accommodation_required_property_type"
                value="Studio"
              />
              Studio
            </label>
          </div>
          <div className="form--ha__checkbox-container form--ha__checkbox-container--inline">
            <label className="form--ha__label--checkbox">
              <Field
                type="checkbox"
                className="form--ha__checkbox"
                name="accommodation_required_property_type"
                value="1 Bedroom"
              />
              1 Bedroom
            </label>
          </div>
          <div className="form--ha__checkbox-container form--ha__checkbox-container--inline">
            <label className="form--ha__label--checkbox">
              <Field
                type="checkbox"
                className="form--ha__checkbox"
                name="accommodation_required_property_type"
                value="2 Bedroom"
              />
              2 Bedroom
            </label>
          </div>
          {errors.accommodation_required_property_type &&
          touched.accommodation_required_property_type ? (
            <p className="form__error">
              {errors.accommodation_required_property_type}
            </p>
          ) : null}
        </fieldset>
      </div>

      {Array.isArray(values.accommodation_required_property_type) &&
        values.accommodation_required_property_type.includes("2 Bedroom") && (
          <>
            <hr className="form--ha__divider" />
            <div className="form__group">
              <label
                className="form__label"
                htmlFor="accommodation_required_large_accommodation_details"
              >
                19a. If you have indicated a two bedroom property, please
                explain why below. You can still register for a larger
                accommodation but this will increase the time you spend waiting
                to be housed. We will also take into account specific needs for
                a two bedroom property.
              </label>
              <Field
                id="title"
                component="textarea"
                name="accommodation_required_large_accommodation_details"
                className="form__textarea"
              />
              {errors.accommodation_required_large_accommodation_details &&
              touched.accommodation_required_large_accommodation_details ? (
                <p className="form__error">
                  {errors.accommodation_required_large_accommodation_details}
                </p>
              ) : null}
            </div>
          </>
        )}

      <hr className="form--ha__divider" />

      <div className="form__group form--ha__group--inputs-inline">
        <label className="form__label form--ha__label--space">
          20. If you have a preferred location or scheme where you would like to
          be offered accommodation, please specify this below. If you are unsure
          please leave blank.
        </label>
        <div className="form__text form--ha__text--scheme-area">
          <label htmlFor="accommodation_required_scheme_area_one">
            <span>(1) </span>Area
          </label>
          <Field
            id="accommodation_required_scheme_area_one"
            type="text"
            name="accommodation_required_scheme_area_one"
          />
        </div>
        {errors.accommodation_required_scheme_area_one &&
        touched.accommodation_required_scheme_area_one ? (
          <p className="form__error">
            {errors.accommodation_required_scheme_area_one}
          </p>
        ) : null}
        <div className="form__text form--ha__text--scheme-name">
          <label htmlFor="accommodation_required_scheme_name_one">
            <span>(1) </span>Scheme Name
          </label>
          <Field
            id="accommodation_required_scheme_name_one"
            type="text"
            name="accommodation_required_scheme_name_one"
          />
        </div>
        {errors.accommodation_required_scheme_name_one &&
        touched.accommodation_required_scheme_name_one ? (
          <p className="form__error">
            {errors.accommodation_required_scheme_name_one}
          </p>
        ) : null}

        <hr className="form--ha__input-divider" />

        <div className="form__text form--ha__text--scheme-area">
          <label htmlFor="accommodation_required_scheme_area_two">
            <span>(2) </span>Area
          </label>
          <Field
            id="accommodation_required_scheme_area_two"
            type="text"
            name="accommodation_required_scheme_area_two"
          />
        </div>
        {errors.accommodation_required_scheme_area_two &&
        touched.accommodation_required_scheme_area_two ? (
          <p className="form__error">
            {errors.accommodation_required_scheme_area_two}
          </p>
        ) : null}
        <div className="form__text form--ha__text--scheme-name">
          <label htmlFor="accommodation_required_scheme_name_two">
            <span>(2) </span>Scheme Name
          </label>
          <Field
            id="accommodation_required_scheme_name_two"
            type="text"
            name="accommodation_required_scheme_name_two"
          />
        </div>
        {errors.accommodation_required_scheme_name_two &&
        touched.accommodation_required_scheme_name_two ? (
          <p className="form__error">
            {errors.accommodation_required_scheme_name_two}
          </p>
        ) : null}

        <hr className="form--ha__input-divider" />

        <div className="form__text form--ha__text--scheme-area">
          <label htmlFor="accommodation_required_scheme_area_three">
            <span>(3) </span>Area
          </label>
          <Field
            id="accommodation_required_scheme_area_three"
            type="text"
            name="accommodation_required_scheme_area_three"
          />
        </div>
        {errors.accommodation_required_scheme_area_three &&
        touched.accommodation_required_scheme_area_three ? (
          <p className="form__error">
            {errors.accommodation_required_scheme_area_three}
          </p>
        ) : null}
        <div className="form__text form--ha__text--scheme-name">
          <label htmlFor="accommodation_required_scheme_name_three">
            <span>(3) </span>Scheme Name
          </label>
          <Field
            id="accommodation_required_scheme_name_three"
            type="text"
            name="accommodation_required_scheme_name_three"
          />
        </div>
        {errors.accommodation_required_scheme_name_three &&
        touched.accommodation_required_scheme_name_three ? (
          <p className="form__error">
            {errors.accommodation_required_scheme_name_three}
          </p>
        ) : null}

        <hr className="form--ha__input-divider" />

        <div className="form__text form--ha__text--scheme-area">
          <label htmlFor="accommodation_required_scheme_area_four">
            <span>(4) </span>Area
          </label>
          <Field
            id="accommodation_required_scheme_area_four"
            type="text"
            name="accommodation_required_scheme_area_four"
          />
        </div>
        {errors.accommodation_required_scheme_area_four &&
        touched.accommodation_required_scheme_area_four ? (
          <p className="form__error">
            {errors.accommodation_required_scheme_area_four}
          </p>
        ) : null}
        <div className="form__text form--ha__text--scheme-name">
          <label htmlFor="accommodation_required_scheme_name_four">
            <span>(4) </span>Scheme Name
          </label>
          <Field
            id="accommodation_required_scheme_name_four"
            type="text"
            name="accommodation_required_scheme_name_four"
          />
        </div>
        {errors.accommodation_required_scheme_name_four &&
        touched.accommodation_required_scheme_name_four ? (
          <p className="form__error">
            {errors.accommodation_required_scheme_name_four}
          </p>
        ) : null}
      </div>
      <p>
        PRHA will attempt to offer accommodation at your preferred locations
        however reserves the right to withhold offers of accommodation in
        schemes where a family member(s) of the applicant currently resides
      </p>

      <hr className="form--ha__divider" />

      <fieldset className="form__group">
        <legend className="form__label" htmlFor="currently_working">
          21. Are you currently working? If yes, how many hours a week?
        </legend>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="currently_working"
              value="Yes currently working"
            />
            Yes
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="currently_working"
              value="Not currently working"
            />
            No
          </label>
        </div>
        {errors.currently_working && touched.currently_working ? (
          <p className="form__error">{errors.currently_working}</p>
        ) : null}
      </fieldset>
      {values.currently_working === "Yes currently working" && (
        <div className="form__group">
          <label className="form__label" htmlFor="current_working_hours">
            If yes how many hours per week?
          </label>
          <Field
            id="current_working_hours"
            type="number"
            name="current_working_hours"
            className="form__text form__text--narrow"
          />
          {errors.current_working_hours && touched.current_working_hours ? (
            <p className="form__error">{errors.current_working_hours}</p>
          ) : null}
        </div>
      )}
      {values.currently_working === "Yes currently working" && (
        <div className="form__group">
          <label
            className="form__label"
            htmlFor="current_working_annual_salary"
          >
            If working what is your annual salary?
          </label>
          <Field
            id="current_working_annual_salary"
            type="number"
            name="current_working_annual_salary"
            className="form__text form__text--narrow"
          />
          {errors.current_working_annual_salary &&
          touched.current_working_annual_salary ? (
            <p className="form__error">
              {errors.current_working_annual_salary}
            </p>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default FieldGroupAccomodationRequired
