import React, { useContext } from "react"
import { Field } from "formik"
import { HousingApplicationFormContext } from "../form"

const FieldGroupYourContactDetails = () => {
  const { errors, touched } = useContext(HousingApplicationFormContext)

  return (
    <div className="form--ha__row">
      <h3 className="section__title">
        <span>Your contact details</span>
      </h3>
      <div className="form__group form__group--inline form--ha__group--telephone">
        <label className="form__label" htmlFor="details_telephone">
          Telephone Number
        </label>
        <Field
          type="text"
          className="form__text"
          id="details_telephone"
          name="details_telephone"
        />
        {errors.details_telephone && touched.details_telephone ? (
          <p className="form__error">{errors.details_telephone}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form--ha__group--mobile">
        <label className="form__label" htmlFor="details_mobile">
          Mobile Number
        </label>
        <Field
          type="text"
          className="form__text"
          id="details_mobile"
          name="details_mobile"
        />
        {errors.details_mobile && touched.details_mobile ? (
          <p className="form__error">{errors.details_mobile}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form--ha__group--email">
        <label className="form__label" htmlFor="details_email">
          Email Address
        </label>
        <Field
          type="email"
          className="form__text"
          id="details_email"
          name="details_email"
        />
        {errors.details_email && touched.details_email ? (
          <p className="form__error">{errors.details_email}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form--ha__group--email-confirm">
        <label className="form__label" htmlFor="details_email_confirm">
          Confirm Email Address
        </label>
        <Field
          type="email"
          className="form__text"
          id="details_email_confirm"
          name="details_email_confirm"
        />
        {errors.details_email_confirm && touched.details_email_confirm ? (
          <p className="form__error">{errors.details_email_confirm}</p>
        ) : null}
      </div>

      <hr className="form--ha__divider" />

      <div className="form__group form__group--inline form--ha__group--nomination">
        <h4 className="form--ha__sub-heading">Direct Applicant/ Nomination</h4>
        <label className="form__label" htmlFor="details_nomination">
          Are you applying for housing directly or have you been nominated for a
          property by the local authority?
        </label>
        <Field
          type="text"
          className="form__text"
          id="details_nomination"
          name="details_nomination"
        />
        {errors.details_nomination && touched.details_nomination ? (
          <p className="form__error">{errors.details_nomination}</p>
        ) : null}
      </div>
    </div>
  )
}
export default FieldGroupYourContactDetails
