import { withFormik } from "formik"
import * as yup from "yup"
import HousingApplicationForm from "./form"
import housingApplicationSubmission from "./submission"

// Telephone Regex for validation
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const HousingApplicationFormik = withFormik({
  mapPropsToValues({
    // Existing Resident
    existing_resident,

    // Your Household
    personal_household_title,
    personal_household_first_name,
    personal_household_surname,
    personal_household_dob,
    personal_household_ni_number,

    // Others in Household
    // Person 1
    household_others_first_title,
    household_others_first_first_name,
    household_others_first_surname,
    household_others_first_dob,
    household_others_first_relationship,
    household_others_first_ni_number,

    // Person 2
    household_others_second_title,
    household_others_second_first_name,
    household_others_second_surname,
    household_others_second_dob,
    household_others_second_relationship,
    household_others_second_ni_number,

    // Current Address
    current_address_line1,
    current_address_line2,
    current_address_postcode,

    // Contact Details
    details_telephone,
    details_mobile,
    details_email,
    details_email_confirm,
    details_nomination,

    // Preferred Contacts
    // Contact 1
    first_preferred_contact_title,
    first_preferred_contact_first_name,
    first_preferred_contact_surname,
    first_preferred_contact_email,
    first_preferred_contact_relationship,
    first_preferred_contact_address_line1,
    first_preferred_contact_address_line2,
    first_preferred_contact_postcode,

    // Current Housing
    // Reasons for application
    current_housing_reasons,

    // Current housing type
    current_housing_current_type,
    current_housing_current_type_other_specify,
    current_housing_current_sheltered,

    // Current property type
    current_housing_current_property,
    current_housing_current_property_other_specify,
    current_housing_current_property_flat_specify,
    current_housing_current_property_do_you_have_to_climb_stairs,

    // Previous Resident PRHA Resident
    previous_resident,
    previous_resident_address,

    // Pet
    pet,
    pet_details,

    // Eligibility
    // Right of Residence
    eligibility_right_of_residence,
    eligibility_right_of_residence_details,

    // PRHA Related
    eligibility_prha_related,
    eligibility_prha_related_details,

    // Housing History
    // Current Address history
    current_property_length_years,
    current_property_length_months,
    current_property_length_address,

    // Landlord Address
    landlord_address,

    // Mortgage arrears
    arrears,

    // Arrears Clear
    arrears_clearing,
    arrears_clearing_details,

    // Evicted
    evicted,

    // ASBO
    asbo,
    asbo_details,

    // Convictions
    convictions,
    convictions_details,

    // Probation Worker
    probation_worker,
    probation_worker_details,

    // Power Of Attorney
    power_of_attorney,
    power_of_attorney_details,

    // Where hear about PRHA?
    where_did_you_hear_about_prha,
    where_did_you_hear_about_prother_specify,

    // Accommodation Required
    // Type Accom
    accommodation_required_type,

    // Floor Level
    accommodation_required_floor_level,

    // Scheme
    accommodation_required_scheme_area_one,
    accommodation_required_scheme_name_one,
    accommodation_required_scheme_area_two,
    accommodation_required_scheme_name_two,
    accommodation_required_scheme_area_three,
    accommodation_required_scheme_name_three,
    accommodation_required_scheme_area_four,
    accommodation_required_scheme_name_four,

    // Required Accomm type
    accommodation_required_property_type,
    accommodation_required_large_accommodation_details,

    // Currently working
    currently_working,
    current_working_hours,
    current_working_annual_salary,

    // Mobility
    mobility_current,
    mobility_get_about,
    mobility_falls,
    mobility_smoke_alcohol,
    mobility_climb_stairs,
    mobility_difficult_tasks,
    mobility_scooter,
    mobility_doctor_details,

    // Declaration
    declaration_full_name,
    declaration_joint_full_name,
  }) {
    return {
      // Existing Resident
      existing_resident: "No",

      // Your Household
      personal_household_title: "no-value",
      personal_household_first_name: "",
      personal_household_surname: "",
      personal_household_dob: null,
      personal_household_ni_number: "",

      // Others in Household
      // Person 1
      household_others_first_title: "no-value",
      household_others_first_first_name: "",
      household_others_first_surname: "",
      household_others_first_dob: null,
      household_others_first_relationship: "",
      household_others_first_ni_number: "",

      // Person 2
      household_others_second_title: "no-value",
      household_others_second_first_name: "",
      household_others_second_surname: "",
      household_others_second_dob: null,
      household_others_second_relationship: "",
      household_others_second_ni_number: "",

      // Current Address
      current_address_line1: "",
      current_address_line2: "",
      current_address_postcode: "",

      // Contact Details
      details_telephone: "",
      details_mobile: "",
      details_email: "",
      details_email_confirm: "",
      details_nomination: "",

      // Preferred Contacts
      // Preferred Contact 1
      first_preferred_contact_title: "no-value",
      first_preferred_contact_first_name: "",
      first_preferred_contact_surname: "",
      first_preferred_contact_email: "",
      first_preferred_contact_relationship: "",
      first_preferred_contact_address_line1: "",
      first_preferred_contact_address_line2: "",
      first_preferred_contact_postcode: "",

      // Preferred Contact 2
      second_preferred_contact_title: "no-value",
      second_preferred_contact_first_name: "",
      second_preferred_contact_surname: "",
      second_preferred_contact_email: "",
      second_preferred_contact_relationship: "",
      second_preferred_contact_address_line1: "",
      second_preferred_contact_address_line2: "",
      second_preferred_contact_postcode: "",

      // Current Housing
      // Reasons for housing application
      current_housing_reasons: "",

      // Current housing type
      current_housing_current_type: "",
      current_housing_current_type_other_specify: "",
      current_housing_current_sheltered: "",

      // Current property type
      current_housing_current_property: "",
      current_housing_current_property_other_specify: "",
      current_housing_current_property_flat_specify: "",
      current_housing_current_property_do_you_have_to_climb_stairs: "",

      // Previous_resident PRHA Resident
      previous_resident: "",
      previous_resident_address: "",

      // Pet
      pet: "",
      pet_details: "",

      // Housing Benefit
      housing_benefit: "",
      housing_benefit_details: "",

      // Your Eligibility
      // Right Of Residence
      eligibility_right_of_residence: "",
      eligibility_right_of_residence_details: "",

      // PRHA Related
      eligibility_prha_related: "",
      eligibility_prha_related_details: "",

      // Current Address history
      current_property_length_years: 0,
      current_property_length_months: 0,
      current_property_length_address: "",

      // Landlord address
      landlord_address: "",

      // Arrears
      arrears: "",

      // Arrears Clear
      arrears_clearing: "N/A",
      arrears_clearing_details: "",

      // Evicted
      evicted: "",

      // ASBO
      asbo: "",
      asbo_details: "",

      // Convictions
      convictions: "",
      convictions_details: "",

      // Probation Worker
      probation_worker: "",
      probation_worker_details: "",

      // Power Of Attorney
      power_of_attorney: "",
      power_of_attorney_details: "",

      // Where hear about PRHA?
      where_did_you_hear_about_prha: "",
      where_did_you_hear_about_prother_specify: "",

      // Accommodation Required
      // Type of Accomm
      accommodation_required_type: "",

      // Floor Level
      accommodation_required_floor_level: "",

      // Scheme
      accommodation_required_scheme_area_one: "",
      accommodation_required_scheme_name_one: "",
      accommodation_required_scheme_area_two: "",
      accommodation_required_scheme_name_two: "",
      accommodation_required_scheme_area_three: "",
      accommodation_required_scheme_name_three: "",
      accommodation_required_scheme_area_four: "",
      accommodation_required_scheme_name_four: "",

      // Required Accomm Type
      accommodation_required_property_type: {},
      accommodation_required_large_accommodation_details: "",

      // Required Accomm Type
      currently_working: "",
      current_working_hours: 0,
      current_working_annual_salary: "",

      // Mobility
      mobility_current: "",
      mobility_get_about: "",
      mobility_falls: "",
      mobility_smoke_alcohol: "",
      mobility_climb_stairs: "",
      mobility_difficult_tasks: "",
      mobility_scooter: "",
      mobility_doctor_details: "",

      // Declaration
      declaration_full_name: "",
      declaration_joint_full_name: "",
    }
  },
  validationSchema: yup.object().shape({
    // Existing Resident
    existing_resident: yup.string().required("Field is required"),

    // Your Household
    personal_household_title: yup.string().required("Title is required"),
    personal_household_first_name: yup
      .string()
      .max(100, "First Name is too long")
      .required("First Name is required"),
    personal_household_surname: yup
      .string()
      .max(100, "Surname is too long")
      .required("Surname is required"),
    personal_household_dob: yup
      .string("Must be a valid DOB")
      .required("DOB is required")
      .nullable(),
    personal_household_ni_number: yup
      .number("Must be a valid NI Number")
      .required("NI Number is required"),

    // Others in Household
    // Person 1
    household_others_first_title: yup.string(),
    household_others_first_first_name: yup
      .string()
      .max(100, "First Name is too long"),
    household_others_first_surname: yup
      .string()
      .max(100, "Surname is too long"),
    household_others_first_dob: yup.string("Must be a valid DOB").nullable(),
    household_others_first_relationship: yup
      .string()
      .max(100, "Relationship is too long"),
    household_others_first_ni_number: yup.number("Must be a valid NI Number"),

    // Person 2
    household_others_second_title: yup.string(),
    household_others_second_first_name: yup
      .string()
      .max(100, "First Name is too long"),
    household_others_second_surname: yup
      .string()
      .max(100, "Surname is too long"),
    household_others_second_dob: yup.string("Must be a valid DOB").nullable(),
    household_others_second_relationship: yup
      .string()
      .max(100, "Relationship is too long"),
    household_others_second_ni_number: yup.number("Must be a valid NI Number"),

    // Current Address
    current_address_line1: yup
      .string()
      .min(3, "Address Line 1 is too short")
      .max(300, "Address Line 1 is too long")
      .required("Address Line 1 is required"),
    current_address_line2: yup
      .string()
      .min(3, "Address Line 2 is too short")
      .max(300, "Address Line 2 is too long"),
    current_address_postcode: yup
      .string()
      .min(5, "Postcode Name is too short")
      .max(10, "Postcode is too long")
      .required("Postcode is required"),

    // Contact Details
    details_telephone: yup
      .string()
      .required("Telephone Number is required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(6, "Number is too short")
      .max(20, "Number is too long"),
    details_mobile: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(6, "Number is too short")
      .max(20, "Number is too long"),
    details_email: yup
      .string()
      .email("Please enter a valid email address")
      .required("This field is required"),
    details_email_confirm: yup
      .string()
      .email()
      .oneOf([yup.ref("details_email"), null], "Email addresses must match")
      .required("This field is required"),
    details_nomination: yup
      .string()
      .max(100, "First Name is too long")
      .required("This field is required"),

    // Preferred Contacts
    // Contact 1
    first_preferred_contact_title: yup.string(),
    first_preferred_contact_first_name: yup
      .string()
      .min(3, "First Name is too short")
      .max(100, "First Name is too long")
      .required("This field is required"),
    first_preferred_contact_surname: yup
      .string()
      .min(3, "Surname is too short")
      .max(100, "Surname is too long")
      .required("This field is required"),
    first_preferred_contact_email: yup
      .string()
      .email("Please enter a valid email address")
      .required("This field is required"),
    first_preferred_contact_relationship: yup
      .string()
      .min(3, "Relationship is too short")
      .max(100, "Relationship is too long")
      .required("This field is required"),
    first_preferred_contact_address_line1: yup
      .string()
      .min(3, "Address Line 1 is too short")
      .max(300, "Address Line 1 is too long")
      .required("This field is required"),
    first_preferred_contact_address_line2: yup
      .string()
      .min(3, "Address Line 2 is too short")
      .max(300, "Address Line 2 is too long"),
    first_preferred_contact_postcode: yup
      .string()
      .min(5, "Postcode is too short")
      .max(10, "Postcode is too long")
      .required("Postcode is required")
      .required("This field is required"),

    // Contact 2
    second_preferred_contact_title: yup.string(),
    second_preferred_contact_first_name: yup
      .string()
      .max(100, "First Name is too long"),
    second_preferred_contact_surname: yup
      .string()
      .max(100, "Surname is too long"),
    second_preferred_contact_email: yup
      .string()
      .email("Please enter a valid email address"),
    second_preferred_contact_relationship: yup
      .string()
      .max(100, "Relationship is too long"),
    second_preferred_contact_address_line1: yup
      .string()
      .max(300, "Address Line 1 is too long"),
    second_preferred_contact_address_line2: yup
      .string()
      .max(300, "Address Line 2 is too long"),
    second_preferred_contact_postcode: yup
      .string()
      .max(10, "Postcode is too long"),

    // Current Housing
    // Reasons
    current_housing_reasons: yup.string().max(800, "First Name is too long"),

    // Current housing type
    current_housing_current_type: yup
      .string()
      .required("This field is required"),
    current_housing_current_type_other_specify: yup
      .string()
      .max(300, "Field is too long"),
    current_housing_current_sheltered: yup
      .string()
      .required("This field is required"),

    // Current property
    current_housing_current_property: yup
      .string()
      .required("This field is required"),
    current_housing_current_property_other_specify: yup
      .string()
      .max(300, "Field is too long"),
    current_housing_current_property_flat_specify: yup
      .string()
      .max(300, "Field is too long"),
    current_housing_current_property_do_you_have_to_climb_stairs: yup.string(),

    // Previous_resident PRHA Resident
    previous_resident: yup.string().required("This field is required"),
    previous_resident_address: yup.string().max(300, "Field is too long"),

    // Pet
    pet: yup.string(),
    pet_details: yup.string().max(300, "Field is too long"),

    // Housing Benefit
    housing_benefit: yup.string(),
    housing_benefit_details: yup.string().max(300, "Field is too long"),

    // Your Eligibility
    // Right of Residence
    eligibility_right_of_residence: yup
      .string()
      .required("This field is required"),
    eligibility_right_of_residence_details: yup
      .string()
      .max(300, "Field is too long"),

    // PRHA Related
    eligibility_prha_related: yup.string().required("This field is required"),
    eligibility_prha_related_details: yup
      .string()
      .max(300, "Field is too long"),

    // Current Address history
    current_property_length_years: yup
      .number("This field must be a number")
      .required("This field is required"),
    current_property_length_months: yup
      .number("This field must be a number")
      .required("This field is required"),
    current_property_length_address: yup.string().max(300, "Field is too long"),

    // Landlord address
    landlord_address: yup.string().max(300, "Field is too long"),

    // Arrears
    arrears: yup.string(),

    // Arrears Clear
    arrears_clearing: yup.string(),
    arrears_clearing_details: yup.string().max(300, "Field is too long"),

    // Evicted
    evicted: yup.string().required("This field is required"),

    // ASBO
    asbo: yup.string().required("This field is required"),
    asbo_details: yup.string().max(300, "Field is too long"),

    // Convictions
    convictions: yup.string().required("This field is required"),
    convictions_details: yup.string().max(300, "Field is too long"),

    // Probation Worker
    probation_worker: yup.string().required("This field is required"),
    probation_worker_details: yup.string().max(300, "Field is too long"),

    // Power Of Attorney
    power_of_attorney: yup.string(),
    power_of_attorney_details: yup.string().max(300, "Field is too long"),

    // Where hear about PRHA?
    where_did_you_hear_about_prha: yup.string(),
    where_did_you_hear_about_prother_specify: yup
      .string()
      .max(300, "Field is too long"),

    // Accommodation Required
    // Type Accomm
    accommodation_required_type: yup
      .string()
      .required("This field is required"),

    // Floor Level
    accommodation_required_floor_level: yup.string(),

    // Scheme
    accommodation_required_scheme_area_one: yup
      .string()
      .max(300, "Field is too long"),
    accommodation_required_scheme_name_one: yup
      .string()
      .max(300, "Field is too long"),
    accommodation_required_scheme_area_two: yup
      .string()
      .max(300, "Field is too long"),
    accommodation_required_scheme_name_two: yup
      .string()
      .max(300, "Field is too long"),
    accommodation_required_scheme_area_three: yup
      .string()
      .max(300, "Field is too long"),
    accommodation_required_scheme_name_three: yup
      .string()
      .max(300, "Field is too long"),
    accommodation_required_scheme_area_four: yup
      .string()
      .max(300, "Field is too long"),
    accommodation_required_scheme_name_four: yup
      .string()
      .max(300, "Field is too long"),

    // Required Accomm Type
    accommodation_required_property_type: yup.object().nullable(),
    accommodation_required_large_accommodation_details: yup
      .string()
      .max(300, "Field is too long"),

    // Currently Working
    currently_working: yup.string().required("This field is required"),
    current_working_hours: yup.number("Must be a number"),
    current_working_annual_salary: yup.string().max(50, "Field is too long"),

    // Mobility
    // Current mobility
    mobility_current: yup.string().max(300, "Field is too long"),

    // Get about home
    mobility_get_about: yup.string().max(300, "Field is too long"),

    // Get Falls
    mobility_falls: yup.string().max(300, "Field is too long"),

    // Get Smoke/Alcohol
    mobility_smoke_alcohol: yup.string().max(300, "Field is too long"),

    // Smoke/Alcohol
    mobility_climb_stairs: yup.string().max(300, "Field is too long"),

    // Difficult Tasks
    mobility_difficult_tasks: yup.string().max(300, "Field is too long"),

    // Mobility Scooter
    mobility_scooter: yup.string(),

    // Doctor Details
    mobility_doctor_details: yup.string().max(300, "Field is too long"),

    // Declaration
    declaration_full_name: yup
      .string()
      .required("Applicant's full name is required"),
    declaration_joint_full_name: yup.string(),
  }),
  handleSubmit(values, { props }) {
    housingApplicationSubmission(values)
  },
})(HousingApplicationForm)

export default HousingApplicationFormik
