import React, { useContext } from "react"
import { Field } from "formik"
import { HousingApplicationFormContext } from "../form"

const FieldGroupPreferredContacts = () => {
  const { errors, touched } = useContext(HousingApplicationFormContext)

  return (
    <div className="form--ha__row form--ha__row--tight">
      <h3 className="section__title">
        <span>
          Details of preferred contact or anyone acting on your behalf
        </span>
      </h3>
      <h4 className="form--ha__sub-heading">
        Preferred Contact / Next of Kin 1
      </h4>
      <div className="form__group form__group--inline form--ha__group--title">
        <label className="form__label" htmlFor="first_preferred_contact_title">
          Title
        </label>
        <Field
          id="title"
          component="select"
          name="first_preferred_contact_title"
          className="form__select form__select--title"
        >
          <option value="no-value" disabled>
            --
          </option>
          <option value="Mr">Mr</option>
          <option value="Mrs">Mrs</option>
          <option value="Miss">Miss</option>
          <option value="Ms">Ms</option>
          <option value="Dr">Dr</option>
        </Field>
        {errors.first_preferred_contact_title && touched.first_preferred_contact_title ? (
          <p className="form__error">{errors.first_preferred_contact_title}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form--ha__group--first-name">
        <label className="form__label" htmlFor="first_preferred_contact_first_name">
        <span>Preferred Contact 1 - </span>First Name
        </label>
        <Field
          type="text"
          className="form__text"
          name="first_preferred_contact_first_name"
          id="first_preferred_contact_first_name"
        />
        {errors.first_preferred_contact_first_name && touched.first_preferred_contact_first_name ? (
          <p className="form__error">{errors.first_preferred_contact_first_name}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form--ha__group--surname">
        <label className="form__label" htmlFor="first_preferred_contact_surname">
        <span>Preferred Contact 1 - </span>Surname
        </label>
        <Field
          type="text"
          className="form__text"
          name="first_preferred_contact_surname"
          id="first_preferred_contact_surname"
        />
        {errors.first_preferred_contact_surname && touched.first_preferred_contact_surname ? (
          <p className="form__error">{errors.first_preferred_contact_surname}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form--ha__group--email">
        <label className="form__label" htmlFor="first_preferred_contact_email">
        <span>Preferred Contact 1 - </span>Email Address
        </label>
        <Field
          type="email"
          className="form__text"
          id="first_preferred_contact_email"
          name="first_preferred_contact_email"
        />
        {errors.first_preferred_contact_email && touched.first_preferred_contact_email ? (
          <p className="form__error">{errors.first_preferred_contact_email}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form--ha__group--relationship2">
        <label className="form__label" htmlFor="first_preferred_contact_relationship">
        <span>Preferred Contact 1 - </span>Relationship
        </label>
        <Field
          type="text"
          className="form__text"
          name="first_preferred_contact_relationship"
          id="first_preferred_contact_relationship"
        />
        {errors.first_preferred_contact_relationship && touched.first_preferred_contact_relationship ? (
          <p className="form__error">{errors.first_preferred_contact_relationship}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form__group--address">
        <label className="form__label" htmlFor="first_preferred_contact_address_line1">
        <span>Preferred Contact 1 - </span>Address Line 1
        </label>
        <Field
          type="text"
          className="form__text"
          id="first_preferred_contact_address_line1"
          name="first_preferred_contact_address_line1"
        />
        {errors.first_preferred_contact_address_line1 && touched.first_preferred_contact_address_line1 ? (
          <p className="form__error">{errors.first_preferred_contact_address_line1}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form__group--address">
        <label className="form__label" htmlFor="first_preferred_contact_address_line2">
        <span>Preferred Contact 1 - </span>Address Line 2
        </label>
        <Field
          type="text"
          className="form__text"
          id="first_preferred_contact_address_line2"
          name="first_preferred_contact_address_line2"
        />
        {errors.first_preferred_contact_address_line2 && touched.first_preferred_contact_address_line2 ? (
          <p className="form__error">{errors.first_preferred_contact_address_line2}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form--ha__group--postcode">
        <label className="form__label" htmlFor="first_preferred_contact_postcode">
        <span>Preferred Contact 1 - </span>Postcode
        </label>
        <Field
          type="text"
          className="form__text"
          id="first_preferred_contact_postcode"
          name="first_preferred_contact_postcode"
        />
        {errors.first_preferred_contact_postcode && touched.first_preferred_contact_postcode ? (
          <p className="form__error">{errors.first_preferred_contact_postcode}</p>
        ) : null}
      </div>

      <hr className="form--ha__divider" />

      <h4 className="form--ha__sub-heading">
        Preferred Contact / Next of Kin 2
      </h4>
      <div className="form__group form__group--inline form--ha__group--title">
        <label className="form__label" htmlFor="second_preferred_contact_title">
          Title
        </label>
        <Field
          id="title"
          component="select"
          name="second_preferred_contact_title"
          className="form__select form__select--title"
        >
          <option value="no-value" disabled>
            --
          </option>
          <option value="Mr">Mr</option>
          <option value="Mrs">Mrs</option>
          <option value="Miss">Miss</option>
          <option value="Ms">Ms</option>
          <option value="Dr">Dr</option>
        </Field>
        {errors.second_preferred_contact_title && touched.second_preferred_contact_title ? (
          <p className="form__error">{errors.second_preferred_contact_title}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form--ha__group--first-name">
        <label className="form__label" htmlFor="second_preferred_contact_first_name">
        <span>Preferred Contact 2 - </span>First Name
        </label>
        <Field
          type="text"
          className="form__text"
          name="second_preferred_contact_first_name"
          id="second_preferred_contact_first_name"
        />
        {errors.second_preferred_contact_first_name && touched.second_preferred_contact_first_name ? (
          <p className="form__error">{errors.second_preferred_contact_first_name}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form--ha__group--surname">
        <label className="form__label" htmlFor="second_preferred_contact_surname">
        <span>Preferred Contact 2 - </span>Surname
        </label>
        <Field
          type="text"
          className="form__text"
          name="second_preferred_contact_surname"
          id="second_preferred_contact_surname"
        />
        {errors.second_preferred_contact_surname && touched.second_preferred_contact_surname ? (
          <p className="form__error">{errors.second_preferred_contact_surname}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form--ha__group--email">
        <label className="form__label" htmlFor="second_preferred_contact_email">
        <span>Preferred Contact 2 - </span>Email Address
        </label>
        <Field
          type="email"
          className="form__text"
          id="second_preferred_contact_email"
          name="second_preferred_contact_email"
        />
        {errors.second_preferred_contact_email && touched.second_preferred_contact_email ? (
          <p className="form__error">{errors.second_preferred_contact_email}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form--ha__group--relationship2">
        <label className="form__label" htmlFor="second_preferred_contact_relationship">
        <span>Preferred Contact 2 - </span>Relationship
        </label>
        <Field
          type="text"
          className="form__text"
          name="second_preferred_contact_relationship"
          id="second_preferred_contact_relationship"
        />
        {errors.second_preferred_contact_relationship && touched.second_preferred_contact_relationship ? (
          <p className="form__error">{errors.second_preferred_contact_relationship}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form__group--address">
        <label className="form__label" htmlFor="second_preferred_contact_address_line1">
        <span>Preferred Contact 2 - </span>Address Line 1
        </label>
        <Field
          type="text"
          className="form__text"
          id="second_preferred_contact_address_line1"
          name="second_preferred_contact_address_line1"
        />
        {errors.second_preferred_contact_address_line1 && touched.second_preferred_contact_address_line1 ? (
          <p className="form__error">{errors.second_preferred_contact_address_line1}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form__group--address">
        <label className="form__label" htmlFor="second_preferred_contact_address_line2">
        <span>Preferred Contact 2 - </span>Address Line 2
        </label>
        <Field
          type="text"
          className="form__text"
          id="second_preferred_contact_address_line2"
          name="second_preferred_contact_address_line2"
        />
        {errors.second_preferred_contact_address_line2 && touched.second_preferred_contact_address_line2 ? (
          <p className="form__error">{errors.second_preferred_contact_address_line2}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form--ha__group--postcode">
        <label className="form__label" htmlFor="second_preferred_contact_postcode">
        <span>Preferred Contact 2 - </span>Postcode
        </label>
        <Field
          type="text"
          className="form__text"
          id="second_preferred_contact_postcode"
          name="second_preferred_contact_postcode"
        />
        {errors.second_preferred_contact_postcode && touched.second_preferred_contact_postcode ? (
          <p className="form__error">{errors.second_preferred_contact_postcode}</p>
        ) : null}
      </div>
    </div>
  )
}

export default FieldGroupPreferredContacts
