import React, { useContext } from "react"
import { Field } from "formik"
import { HousingApplicationFormContext } from "../form"

const FieldGroupYourHousingHistoryB = () => {
  const { values, errors, touched } = useContext(HousingApplicationFormContext)

  return (
    <div className="form--ha__row form--ha__row--tight">
      <p>
        PRHA has a responsibility to ensure our estates are pleasant and
        sustainable communities for residents. We will try and accommodate all
        applicants where we can, but reserve the right to exclude applicants if
        they do not meet our reasonable lettings criteria.
      </p>
      <p>
        In order that we can consider your application, please answer <i>all</i>{" "}
        of the following questions.
      </p>
      <fieldset className="form__group">
        <legend className="form__label" htmlFor="evicted">
          11. Have you or anyone in your household been evicted from or been
          subject to possession proceedings within the last 2 years? (If yes
          please provide details in question 12)
        </legend>
        <div className="form--ha__radio form--ha__radio--inline">
          <label htmlFor="evicted_yes">
            <Field
              id="evicted_yes"
              type="radio"
              name="evicted"
              value="Yes someone in my household has been evicted"
            />
            Yes
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label htmlFor="evicted_no">
            <Field
              id="evicted_no"
              type="radio"
              name="evicted"
              value="No someone in my household has not been evicted"
            />
            No
          </label>
        </div>
        {errors.evicted && touched.evicted ? (
          <p className="form__error">{errors.evicted}</p>
        ) : null}
      </fieldset>

      <hr className="form--ha__divider" />

      <fieldset className="form__group">
        <label className="form__label" htmlFor="asbo">
          12. Have you or anyone in your household been served with an
          Anti-Social Behaviour Order (ASBO)/a Notice to Quit/Notice of
          Intention to Seek Possession/injunction or been asked to sign an
          Acceptable behaviour Contract (ABC) for Anti-Social Behaviour within
          the last 2 years?
        </label>
        <div className="form--ha__radio form--ha__radio--inline">
          <label htmlFor="asbo_yes">
            <Field id="asbo_yes" type="radio" name="asbo" value="Yes - ASBO" />
            Yes
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label htmlFor="asbo_no">
            <Field id="asbo_no" type="radio" name="asbo" value="No - ASBO" />
            No
          </label>
        </div>
        {errors.asbo && touched.asbo ? (
          <p className="form__error">{errors.asbo}</p>
        ) : null}
      </fieldset>
      {values.evicted === "Yes someone in my household has been evicted" ||
      values.asbo === "Yes - ASBO" ? (
        <div className="form__group">
          <label htmlFor="asbo_details" className="form__label">
            If the answer to question 11 or 12 above is yes, please provide
            details:
          </label>
          <Field
            id="asbo_details"
            component="textarea"
            name="asbo_details"
            className="form__textarea"
          />
          {errors.asbo_details && touched.asbo_details ? (
            <p className="form__error">{errors.asbo_details}</p>
          ) : null}
        </div>
      ) : null}

      <hr className="form--ha__divider" />

      <fieldset className="form__group">
        <legend className="form__label" htmlFor="convictions">
          13. Do you or anyone in your household have convictions for a criminal
          offence other than a spent conviction under the Rehabilitation of
          Offender Act 1974?
        </legend>
        <div className="form--ha__radio form--ha__radio--inline">
          <label htmlFor="convictions_yes">
            <Field
              id="convictions_yes"
              type="radio"
              name="convictions"
              value="Yes someone in my household has convictions"
            />
            Yes
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label htmlFor="convictions_no">
            <Field
              id="convictions_no"
              type="radio"
              name="convictions"
              value="No one in my household has convictions"
            />
            No
          </label>
        </div>
        {errors.convictions && touched.convictions ? (
          <p className="form__error">{errors.convictions}</p>
        ) : null}
      </fieldset>
      {values.convictions === "Yes someone in my household has convictions" && (
        <div className="form__group">
          <label htmlFor="convictions_details" className="form__label">
            If yes, please give details of the offence, date of conviction and
            sentence:
          </label>
          <Field
            id="convictions_details"
            component="textarea"
            name="convictions_details"
            className="form__textarea"
          />
          {errors.convictions_details && touched.convictions_details ? (
            <p className="form__error">{errors.convictions_details}</p>
          ) : null}
        </div>
      )}

      <hr className="form--ha__divider" />

      <fieldset className="form__group">
        <legend className="form__label" htmlFor="probation_worker">
          14. Do you or anyone in your household have a Probation Worker?
        </legend>
        <div className="form--ha__radio form--ha__radio--inline">
          <label htmlFor="probation_worker_yes">
            <Field
              id="probation_worker_yes"
              type="radio"
              name="probation_worker"
              value="Yes someone in my household has a Probation Worker"
            />
            Yes
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label htmlFor="probation_worker_no">
            <Field
              id="probation_worker_no"
              type="radio"
              name="probation_worker"
              value="No one in my household has a Probation Worker"
            />
            No
          </label>
        </div>
        {errors.probation_worker && touched.probation_worker ? (
          <p className="form__error">{errors.probation_worker}</p>
        ) : null}
      </fieldset>
      {values.probation_worker ===
        "Yes someone in my household has a Probation Worker" && (
        <div className="form__group">
          <label htmlFor="probation_worker_details" className="form__label">
            If yes, please give name and contact details for the Probation
            worker:
          </label>
          <Field
            id="probation_worker_details"
            component="textarea"
            name="probation_worker_details"
            className="form__textarea"
          />
          {errors.probation_worker_details &&
          touched.probation_worker_details ? (
            <p className="form__error">{errors.probation_worker_details}</p>
          ) : null}
        </div>
      )}

      <hr className="form--ha__divider" />

      <fieldset className="form__group">
        <legend className="form__label" htmlFor="power_of_attorney">
          15. Do you have a person or persons acting for you under a Power of
          Attorney?
        </legend>
        <div className="form--ha__radio form--ha__radio--inline">
          <label htmlFor="power_of_attorney_yes">
            <Field
              id="power_of_attorney_yes"
              type="radio"
              name="power_of_attorney"
              value="Yes I have a person or persons acting for me under a Power of Attorney?"
            />
            Yes
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label htmlFor="power_of_attorney_no">
            <Field
              id="power_of_attorney_no"
              type="radio"
              name="power_of_attorney"
              value="No I do not have a person or persons acting for me under a Power of Attorney?"
            />
            No
          </label>
        </div>
        {errors.power_of_attorney && touched.power_of_attorney ? (
          <p className="form__error">{errors.power_of_attorney}</p>
        ) : null}
      </fieldset>
      {values.power_of_attorney ===
        "Yes I have a person or persons acting for me under a Power of Attorney?" && (
        <div className="form__group">
          <label className="form__label">
            If yes, please give name and contact details for the party with
            Power of Attorney:
          </label>
          <Field
            id="power_of_attorney_details"
            component="textarea"
            name="power_of_attorney_details"
            className="form__textarea"
          />
          {errors.power_of_attorney_details &&
          touched.power_of_attorney_details ? (
            <p className="form__error">{errors.power_of_attorney_details}</p>
          ) : null}
        </div>
      )}

      <hr className="form--ha__divider" />

      <FieldHHPrhaMarketing errors={errors} touched={touched} />
    </div>
  )
}

const FieldHHPrhaMarketing = () => {
  const { values, errors, touched } = useContext(HousingApplicationFormContext)

  return (
    <>
      <fieldset className="form__group">
        <legend
          id="where_did_you_hear_about_prha"
          className="form__label"
          htmlFor="where_did_you_hear_about_prha"
        >
          16. Where did you hear about PRHA Housing Association?
        </legend>
        <div
          className="form--ha__radio-cols"
          role="group"
          aria-labelledby="where_did_you_hear_about_prha"
        >
          <div className="form--ha__radio-col">
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="where_did_you_hear_about_prha"
                  value="Local Council"
                />
                Local Council
              </label>
            </div>
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="where_did_you_hear_about_prha"
                  value="PRHA Website"
                />
                PRHA Website
              </label>
            </div>

            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="where_did_you_hear_about_prha"
                  value="PRHA tenant"
                />
                PRHA Tenant
              </label>
            </div>
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="where_did_you_hear_about_prha"
                  value="Family/Friend"
                />
                Family/Friend
              </label>
            </div>
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="where_did_you_hear_about_prha"
                  value="Citizens Advice Bureau"
                />
                Citizens Advice Bureau
              </label>
            </div>
          </div>
          <div className="form--ha__radio-col">
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="where_did_you_hear_about_prha"
                  value="Age UK"
                />
                Age UK
              </label>
            </div>
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="where_did_you_hear_about_prha"
                  value="Newspaper"
                />
                Newspaper
              </label>
            </div>
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="where_did_you_hear_about_prha"
                  value="Other Housing Association"
                />
                Other Housing Association
              </label>
            </div>
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="where_did_you_hear_about_prha"
                  value="EAC/HousingCare website"
                />
                EAC/HousingCare website
              </label>
            </div>
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="where_did_you_hear_about_prha"
                  value="Other"
                />
                Other
              </label>
            </div>
          </div>
          {errors.where_did_you_hear_about_prha &&
          touched.where_did_you_hear_about_prha ? (
            <p className="form__error">
              {errors.where_did_you_hear_about_prha}
            </p>
          ) : null}
        </div>
      </fieldset>
      {values.where_did_you_hear_about_prha === "Other" && (
        <div className="form__group">
          <label
            className="form__label"
            htmlFor="where_did_you_hear_about_prother_specify"
          >
            If Other, please specify:
          </label>
          <Field
            type="text"
            id="where_did_you_hear_about_prother_specify"
            name="where_did_you_hear_about_prother_specify"
            className="form__text"
          />
          {errors.where_did_you_hear_about_prother_specify &&
          touched.where_did_you_hear_about_prother_specify ? (
            <p className="form__error">
              {errors.where_did_you_hear_about_prother_specify}
            </p>
          ) : null}
        </div>
      )}
    </>
  )
}

export default FieldGroupYourHousingHistoryB
