import React, { useContext } from "react"
import { Field } from "formik"
import { HousingApplicationFormContext } from "../form"

const FieldGroupCurrentAddress = () => {
  const { errors, touched } = useContext(HousingApplicationFormContext)
  return (
    <div className="form--ha__row">
      <h3 className="section__title">
        <span>Your current address</span>
      </h3>
      <div className="form__group form__group--inline form__group--address">
        <label className="form__label" htmlFor="current_address_line1">
          Address Line 1
        </label>
        <Field
          type="text"
          className="form__text"
          id="current_address_line1"
          name="current_address_line1"
        />
        {errors.current_address_line1 && touched.current_address_line1 ? (
          <p className="form__error">{errors.current_address_line1}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form__group--address">
        <label className="form__label" htmlFor="current_address_line2">
          Address Line 2
        </label>
        <Field
          type="text"
          className="form__text"
          id="current_address_line2"
          name="current_address_line2"
        />
        {errors.current_address_line2 && touched.current_address_line2 ? (
          <p className="form__error">{errors.current_address_line2}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form--ha__group--postcode">
        <label className="form__label" htmlFor="current_address_postcode">
          Postcode
        </label>
        <Field
          type="text"
          className="form__text"
          id="current_address_postcode"
          name="current_address_postcode"
        />
        {errors.current_address_postcode && touched.current_address_postcode ? (
          <p className="form__error">{errors.current_address_postcode}</p>
        ) : null}
      </div>
    </div>
  )
}

export default FieldGroupCurrentAddress
