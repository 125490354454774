import React, { useContext } from "react"
import { Field } from "formik"
import { HousingApplicationFormContext } from "../form"

const FieldGroupDeclaration = () => {
  const { errors, touched } = useContext(HousingApplicationFormContext)
  return (
    <div className="form--ha__row form--ha__row--tight">
      <h3 className="section__title">
        <span>Declaration</span>
      </h3>
      <p>
        Under the Data Protection Act we must tell you what we will use your
        personal details and sensitive data for and we must store it safely. The
        information you have given will be used to allow us to assess and
        prioritise your application for housing. On occasion we are required to
        supply statistics to organisations that regulate us. We will not divulge
        any information we hold about you unless you agree, we are required to
        do so by law or we have to do so in order to protect our rights.
      </p>
      <p>
        I/We* confirm that the information I/we* have supplied is accurate and
        may be held by PRHA in accordance with the particulars above. I/We*
        understand that if I/we* have knowingly or recklessly given any false
        information or withheld information in connection with this application
        and I am/we* are granted the tenancy of a PRHA property as a result of
        this, my/our* home may be repossessed under Schedule 2 of the Housing
        Act 1985 (as amended). I/We* understand that the completion of this form
        does not imply that I/we* will automatically be entitled to an offer of
        accommodation.
      </p>
      <p>
        I/We* give my/our* permission and consent for PRHA to obtain any
        relevant information about me/us* from any relevant agency. I/We*
        understand that these relevant agencies may include but are not limited
        to any police service, previous landlord, probation service or social
        service departments of local authorities. I/We* agree to notify PRHA of
        any change in my/our circumstances that may affect this application.
      </p>
      <div className="form__group">
        <h4>
          Please provide the applicant name as your signature. <br />
          If the application is a joint one, please add the joint applicant
          name.
        </h4>
      </div>
      <div className="form__group form__group--inline form--ha__group--signature">
        <label className="form__label" htmlFor="declaration_full_name">
          Applicant Full Name
        </label>
        <Field
          id="declaration_full_name"
          type="text"
          name="declaration_full_name"
          className="form__text"
        />
        {errors.declaration_full_name && touched.declaration_full_name ? (
          <p className="form__error">{errors.declaration_full_name}</p>
        ) : null}
      </div>
      <div className="form__group form__group--inline form--ha__group--signature">
        <label className="form__label" htmlFor="declaration_joint_full_name">
          Joint Applicant Full Name
        </label>
        <Field
          id="declaration_joint_full_name"
          type="text"
          name="declaration_joint_full_name"
          className="form__text"
        />
        {errors.declaration_joint_full_name &&
        touched.declaration_joint_full_name ? (
          <p className="form__error">{errors.declaration_joint_full_name}</p>
        ) : null}
      </div>
    </div>
  )
}
export default FieldGroupDeclaration
