import React, { useContext } from "react"
import { Field } from "formik"
import { HousingApplicationFormContext } from "../form"

const FieldGroupMobility = () => {
  const { errors, touched } = useContext(HousingApplicationFormContext)

  return (
    <div className="form--ha__row form--ha__row--tight">
      <h3 className="section__title">
        <span>22. Mobility</span>
      </h3>
      <div className="form__group">
        <label className="form__label" htmlFor="mobility_current">
          How would you describe your current mobility?
        </label>
        <Field
          id="mobility_current"
          component="textarea"
          name="mobility_current"
          className="form__textarea"
        />
        {errors.mobility_current && touched.mobility_current ? (
          <p className="form__error">{errors.mobility_current}</p>
        ) : null}
      </div>

      <hr className="form--ha__divider" />

      <div className="form__group">
        <label className="form__label" htmlFor="mobility_get_about">
          Have you been able to get about easily in your present home?
        </label>
        <Field
          id="mobility_get_about"
          component="textarea"
          name="mobility_get_about"
          className="form__textarea"
        />
        {errors.mobility_get_about && touched.mobility_get_about ? (
          <p className="form__error">{errors.mobility_get_about}</p>
        ) : null}
      </div>

      <hr className="form--ha__divider" />

      <div className="form__group">
        <label className="form__label" htmlFor="mobility_falls">
          Have you had any falls? How frequently have you fallen and what seems
          to cause the falls?
        </label>
        <Field
          id="mobility_falls"
          component="textarea"
          name="mobility_falls"
          className="form__textarea"
        />
        {errors.mobility_falls && touched.mobility_falls ? (
          <p className="form__error">{errors.mobility_falls}</p>
        ) : null}
      </div>

      <hr className="form--ha__divider" />

      <div className="form__group">
        <label className="form__label" htmlFor="mobility_smoke_alcohol">
          Do you smoke or use alcohol?
        </label>
        <Field
          id="mobility_smoke_alcohol"
          component="textarea"
          name="mobility_smoke_alcohol"
          className="form__textarea"
        />
        {errors.mobility_smoke_alcohol && touched.mobility_smoke_alcohol ? (
          <p className="form__error">{errors.mobility_smoke_alcohol}</p>
        ) : null}
      </div>

      <div className="form__group">
        <label className="form__label" htmlFor="mobility_climb_stairs">
          Are you able to climb stairs?
        </label>
        <Field
          id="mobility_climb_stairs"
          component="textarea"
          name="mobility_climb_stairs"
          className="form__textarea"
        />
        {errors.mobility_climb_stairs && touched.mobility_climb_stairs ? (
          <p className="form__error">{errors.mobility_climb_stairs}</p>
        ) : null}
      </div>

      <hr className="form--ha__divider" />

      <div className="form__group">
        <label className="form__label" htmlFor="mobility_difficult_tasks">
          Do you have difficulty with any day to day tasks such as cooking or
          cleaning?
        </label>
        <Field
          id="mobility_difficult_tasks"
          component="textarea"
          name="mobility_difficult_tasks"
          className="form__textarea"
        />
        {errors.mobility_difficult_tasks && touched.mobility_difficult_tasks ? (
          <p className="form__error">{errors.mobility_difficult_tasks}</p>
        ) : null}
      </div>

      <hr className="form--ha__divider" />

      <fieldset className="form__group">
        <legend className="form__label" htmlFor="mobility_scooter">
          Do you use a mobility scooter?
        </legend>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="mobility_scooter"
              value="Yes I use a mobility scooter"
            />
            Yes
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="mobility_scooter"
              value="No I don't use a mobility scooter"
            />
            No
          </label>
        </div>
        {errors.mobility_scooter && touched.mobility_scooter ? (
          <p className="form__error">{errors.mobility_scooter}</p>
        ) : null}
      </fieldset>

      <hr className="form--ha__divider" />

      <div className="form__group">
        <label className="form__label" htmlFor="mobility_doctor_details">
          Name and Address of Doctor:
        </label>
        <Field
          id="mobility_doctor_details"
          component="textarea"
          name="mobility_doctor_details"
          className="form__textarea"
        />
        {errors.mobility_doctor_details && touched.mobility_doctor_details ? (
          <p className="form__error">{errors.mobility_doctor_details}</p>
        ) : null}
      </div>
    </div>
  )
}

export default FieldGroupMobility
