import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts"
import Seo from "../components/seo"
import Header from "../components/header"
import PageTitle from "../components/pageTitle"
import PageIntro from "../components/pageIntro"
import Features from "../components/features"
import HousingApplicationFormik from "../components/forms/housingApplication/index"

const HousingApplication = (props) => {
  const currentPage = props.data.allWpPage.edges[0].node,
    pageId = currentPage.parentId === 0 ? currentPage.id : currentPage.parentId,
    pageTitle =
      currentPage.parentId === 0
        ? currentPage.title
        : `Housing Application Form`,
    introTitle =
      currentPage.introduction != null
        ? currentPage.introduction.introTitle
        : "",
    introDesc = `<p>All applicants should complete this form as fully as possible.</p>
    <p>If you have any problems in completing the form or if you would like to discuss your application please contact the Head Office team on 023 92 754791.</p>`

  return (
    <Layout>
      <div className="page">
        <Seo seoTitle={currentPage.title} seoDesc={""} />
        <Header />
        <PageTitle pageId={pageId} pageTitle={pageTitle} />
        <PageIntro introTitle={introTitle} introDesc={introDesc} />

        <section className="section sectionWhite">
          <div className="containerFluid">
            <div className="row center-bp4">
              <div className="col-bp1-12 col-bp4-10 col-bp5-9 col-bp6-7">
                <div
                  dangerouslySetInnerHTML={{ __html: currentPage.content }}
                  className="entry"
                />

                <HousingApplicationFormik />
              </div>
            </div>
          </div>
        </section>

        <section className="section sectionWhite section--close-btm">
          <div className="features">
            <div className="containerFluid">
              <div className="row">
                <div className="col-bp1-12">
                  <Features />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const childPageQuery = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          content
          slug
          introduction {
            introTitle
            intro
          }
        }
      }
    }
  }
`

export default HousingApplication
