import React from "react"
import { Form } from "formik"
import LinkCog from "../../linkCog"

import FieldGroupExistingResident from "./fieldGroups/existingResident"
import FieldGroupYourHousehold from "./fieldGroups/yourHousehold"
import FieldGroupOthersInHousehold from "./fieldGroups/othersInHousehold"
import FieldGroupCurrentAddress from "./fieldGroups/currentAddress"
import FieldGroupYourContactDetails from "./fieldGroups/yourContactDetails"
import FieldGroupPreferredContacts from "./fieldGroups/preferredContacts"
import FieldGroupCurrentHousing from "./fieldGroups/currentHousing"
import FieldGroupYourEligibility from "./fieldGroups/yourEligibility"
import FieldGroupYourHousingHistoryA from "./fieldGroups/yourHousingHistoryA"
import FieldGroupYourHousingHistoryB from "./fieldGroups/yourHousingHistoryB"
import FieldGroupAccomodationRequired from "./fieldGroups/accomodationRequired"
import FieldGroupMobility from "./fieldGroups/mobility"
import FieldGroupDeclaration from "./fieldGroups/declaration"

export const HousingApplicationFormContext = React.createContext()

const HousingApplicationForm = ({
  values,
  errors,
  touched,
  isSubmitting,
  setFieldValue,
}) => (
  <Form
    className="form form--ha"
    name="housing_application"
    method="post"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
  >
    <input type="hidden" name="form-name" value="housing_application" />

    <HousingApplicationFormContext.Provider
      value={{ setFieldValue, values, errors, touched }}
    >
      <FieldGroupExistingResident />
      <FieldGroupYourHousehold />
      <FieldGroupOthersInHousehold />
      <FieldGroupCurrentAddress />
      <FieldGroupYourContactDetails />
      <FieldGroupPreferredContacts />
      <FieldGroupCurrentHousing />
      <FieldGroupYourEligibility />
      <FieldGroupYourHousingHistoryA />
      <FieldGroupYourHousingHistoryB />
      <FieldGroupAccomodationRequired />
      <FieldGroupMobility />
      <FieldGroupDeclaration />

      {JSON.stringify(errors) !== "{}" ? (
        <ErrorSummary errors={errors} />
      ) : null}

      <button
        className="button button--gold"
        type="submit"
        disabled={isSubmitting}
      >
        Send this form <LinkCog />
      </button>
    </HousingApplicationFormContext.Provider>
  </Form>
)

const ErrorSummary = ({ errors }) => {
  return (
    <p className="form--ha__errors">
      Some fields have errors. Please make sure you have completed the form
      correctly.
    </p>
  )
}

export default HousingApplicationForm
