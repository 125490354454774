import React, { useContext } from "react"
import { Field } from "formik"
import { HousingApplicationFormContext } from "../form"

const FieldGroupYourEligibility = () => {
  const { values, errors, touched } = useContext(HousingApplicationFormContext)

  return (
    <div className="form--ha__row form--ha__row--tight">
      <h3 className="section__title">
        <span>Your eligibility</span>
      </h3>
      <fieldset className="form__group">
        <legend
          className="form__label"
          htmlFor="eligibility_right_of_residence"
        >
          5. Do you, and everyone to be housed with you have the right of
          residence in the UK?
        </legend>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="eligibility_right_of_residence"
              value="Yes everyone to be housed with me has the right of residence in the UK"
            />
            Yes
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="eligibility_right_of_residence"
              value="Not everyone to be housed with me has the right of residence in the UK"
            />
            No
          </label>
        </div>
        {errors.eligibility_right_of_residence &&
        touched.eligibility_right_of_residence ? (
          <p className="form__error">{errors.eligibility_right_of_residence}</p>
        ) : null}
      </fieldset>
      {values.eligibility_right_of_residence ===
        "Not everyone to be housed with me has the right of residence in the UK" && (
        <div className="form__group">
          <label
            className="form__label"
            htmlFor="eligibility_right_of_residence_details"
          >
            Please give further details:
          </label>
          <Field
            id="eligibility_right_of_residence_details"
            component="textarea"
            name="eligibility_right_of_residence_details"
            className="form__textarea"
          />
          {errors.eligibility_right_of_residence_details &&
          touched.eligibility_right_of_residence_details ? (
            <p className="form__error">
              {errors.eligibility_right_of_residence_details}
            </p>
          ) : null}
        </div>
      )}

      <hr className="form--ha__divider" />

      <fieldset className="form__group">
        <legend className="form__label" htmlFor="eligibility_prha_related">
          6. Are you, or anyone to be housed with you related to a PRHA member
          of staff or a member of its Board?
        </legend>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="eligibility_prha_related"
              value="Yes someone housed with me is related to a PRHA member of staff or a member of its Board"
            />
            Yes
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="eligibility_prha_related"
              value="No someone housed with me is not related to a PRHA member of staff or a member of its Board"
            />
            No
          </label>
        </div>
        {errors.eligibility_prha_related && touched.eligibility_prha_related ? (
          <p className="form__error">{errors.eligibility_prha_related}</p>
        ) : null}
      </fieldset>
      {values.eligibility_prha_related ===
        "Yes someone housed with me is related to a PRHA member of staff or a member of its Board" && (
        <div className="form__group">
          <label
            className="form__label"
            htmlFor="eligibility_prha_related_details"
          >
            Please give further details:
          </label>
          <Field
            id="eligibility_prha_related_details"
            component="textarea"
            name="eligibility_prha_related_details"
            className="form__textarea"
          />
          {errors.eligibility_prha_related_details &&
          touched.eligibility_prha_related_details ? (
            <p className="form__error">
              {errors.eligibility_prha_related_details}
            </p>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default FieldGroupYourEligibility
