import React, { useContext } from "react"
import { Field } from "formik"
import { HousingApplicationFormContext } from "../form"

const FieldGroupCurrentHousing = () => {
  const { values, errors, touched } = useContext(HousingApplicationFormContext)

  return (
    <div className="form--ha__row form--ha__row--tight">
      <h3 className="section__title">
        <span>Your current housing</span>
      </h3>

      <FieldCurrentHousingReasons errors={errors} touched={touched} />
      <hr className="form--ha__divider" />
      <FieldCurrentHousingSelection
        values={values}
        errors={errors}
        touched={touched}
      />
      <hr className="form--ha__divider" />
      <FieldCurrentlyResiding
        values={values}
        errors={errors}
        touched={touched}
      />
      <hr className="form--ha__divider" />
      <FieldPreviousResident
        values={values}
        errors={errors}
        touched={touched}
      />
      <hr className="form--ha__divider" />
      <FieldPet values={values} errors={errors} touched={touched} />
      <hr className="form--ha__divider" />
      <FieldHousingBenefit values={values} errors={errors} touched={touched} />
    </div>
  )
}

const FieldCurrentHousingReasons = ({ errors, touched }) => (
  <div className="form__group">
    <label className="form__label" htmlFor="current_housing_reasons">
      2. Below give brief details of your reasons for wanting to move to a PRHA
      property:
    </label>
    <Field
      id="title"
      component="textarea"
      name="current_housing_reasons"
      className="form__textarea"
    />
    {errors.current_housing_reasons && touched.current_housing_reasons ? (
      <p className="form__error">{errors.current_housing_reasons}</p>
    ) : null}
  </div>
)

const FieldCurrentHousingSelection = () => {
  const { values, errors, touched } = useContext(HousingApplicationFormContext)
  return (
    <>
      <fieldset className="form__group">
        <legend id="current_housing_current_type" className="form__label">
          3. Please indicate which applies to your current housing
        </legend>
        <div
          className="form--ha__radio-cols"
          role="group"
          aria-labelledby="current_housing_current_type"
        >
          <div className="form--ha__radio-col">
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="current_housing_current_type"
                  value="Local Authority tenant"
                />
                Local Authority tenant
              </label>
            </div>
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="current_housing_current_type"
                  value="Social landlord/HA tenant"
                />
                Social landlord/HA tenant
              </label>
            </div>

            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="current_housing_current_type"
                  value="Private tenant"
                />
                Private tenant
              </label>
            </div>
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="current_housing_current_type"
                  value="Tied accomodation"
                />
                Tied accommodation
              </label>
            </div>
          </div>
          <div className="form--ha__radio-col">
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="current_housing_current_type"
                  value="Owner occupier"
                />
                Owner occupier
              </label>
            </div>
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="current_housing_current_type"
                  value="Living with family/friend"
                />
                Living with family/friend
              </label>
            </div>
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="current_housing_current_type"
                  value="Temporary Accomomdation"
                />
                Temporary accommodation
              </label>
            </div>
          </div>
        </div>
        <div className="form--ha__radio">
          <label>
            <Field
              type="radio"
              name="current_housing_current_type"
              value="Other"
            />
            Other
          </label>
        </div>
        {errors.current_housing_current_type &&
        touched.current_housing_current_type ? (
          <p className="form__error">{errors.current_housing_current_type}</p>
        ) : null}
      </fieldset>
      {values.current_housing_current_type === "Other" && (
        <div className="form__group">
          <label
            className="form__label"
            htmlFor="current_housing_current_type_other_specify"
          >
            If other, please specify:
          </label>
          <Field
            type="text"
            id="current_housing_current_type_other_specify"
            name="current_housing_current_type_other_specify"
            className="form__text"
          />
          {errors.current_housing_current_type_other_specify &&
          touched.current_housing_current_type_other_specify ? (
            <p className="form__error">
              {errors.current_housing_current_type_other_specify}
            </p>
          ) : null}
        </div>
      )}

      <fieldset className="form__group">
        <legend className="form__label">
          Is your current accommodation is within a Sheltered Accommodation?
        </legend>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="current_housing_current_sheltered"
              value="Sheltered Accommodation"
            />
            Yes
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="current_housing_current_sheltered"
              value="Not Sheltered Accommodation"
            />
            No
          </label>
        </div>
        {errors.current_housing_current_sheltered &&
        touched.current_housing_current_sheltered ? (
          <p className="form__error">
            {errors.current_housing_current_sheltered}
          </p>
        ) : null}
      </fieldset>
    </>
  )
}

const FieldCurrentlyResiding = () => {
  const { values, errors, touched } = useContext(HousingApplicationFormContext)

  return (
    <>
      <fieldset className="form__group">
        <legend id="current_housing_current_property" className="form__label">
          3a. Are you currently residing in a:
        </legend>
        <div
          className="form--ha__radio-cols"
          aria-labelledby="current_housing_current_property"
        >
          <div className="form--ha__radio-col">
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="current_housing_current_property"
                  value="House"
                />
                House
              </label>
            </div>
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="current_housing_current_property"
                  value="Bungalow"
                />
                Bungalow
              </label>
            </div>

            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="current_housing_current_property"
                  value="Flat with a lift"
                />
                Flat with a lift
              </label>
            </div>
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="current_housing_current_property"
                  value="Mobile Home"
                />
                Mobile home
              </label>
            </div>
          </div>
          <div className="form--ha__radio-col">
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="current_housing_current_property"
                  value="Room in shared House"
                />
                Room in shared House
              </label>
            </div>
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="current_housing_current_property"
                  value="Flat without a lift"
                />
                Flat without a lift
              </label>
            </div>
            <div className="form--ha__radio">
              <label>
                <Field
                  type="radio"
                  name="current_housing_current_property"
                  value="Sofa Surfing / Homeless"
                />
                Sofa Surfing / Homeless
              </label>
            </div>
          </div>
        </div>
        <div className="form--ha__radio">
          <label>
            <Field
              type="radio"
              name="current_housing_current_property"
              value="Other"
            />
            Other
          </label>
        </div>

        {errors.current_housing_current_property &&
        touched.current_housing_current_property ? (
          <p className="form__error">
            {errors.current_housing_current_property}
          </p>
        ) : null}
      </fieldset>

      {values.current_housing_current_property === "Other" && (
        <div className="form__group">
          <label
            className="form__label"
            htmlFor="current_housing_current_property_other_specify"
          >
            If other, please specify:
          </label>
          <Field
            type="text"
            id="current_housing_current_property_other_specify"
            name="current_housing_current_property_other_specify"
            className="form__text"
          />
          {errors.current_housing_current_property_other_specify &&
          touched.current_housing_current_property_other_specify ? (
            <p className="form__error">
              {errors.current_housing_current_property_other_specify}
            </p>
          ) : null}
        </div>
      )}
      <div className="form__group">
        <label
          className="form__label"
          htmlFor="current_housing_current_property_flat_specify"
        >
          If you live in a flat, what floor is your property on?
        </label>
        <Field
          type="text"
          id="current_housing_current_property_flat_specify"
          name="current_housing_current_property_flat_specify"
          className="form__text"
        />
        {errors.current_housing_current_property_flat_specify &&
        touched.current_housing_current_property_flat_specify ? (
          <p className="form__error">
            {errors.current_housing_current_property_flat_specify}
          </p>
        ) : null}
      </div>
      <fieldset className="form__group">
        <legend
          className="form__legend"
          htmlFor="current_housing_current_property_do_you_have_to_climb_stairs"
        >
          Do you have to climb stairs to access or get about in your home?
        </legend>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="current_housing_current_property_do_you_have_to_climb_stairs"
              value="Yes I have to climb stairs in my current home"
            />
            Yes
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="current_housing_current_property_do_you_have_to_climb_stairs"
              value="No I do not have to climb stairs in my current home"
            />
            No
          </label>
        </div>
        {errors.current_housing_current_property_do_you_have_to_climb_stairs &&
        touched.current_housing_current_property_do_you_have_to_climb_stairs ? (
          <p className="form__error">
            {
              errors.current_housing_current_property_do_you_have_to_climb_stairs
            }
          </p>
        ) : null}
      </fieldset>
    </>
  )
}

const FieldPreviousResident = () => {
  const { values, errors, touched } = useContext(HousingApplicationFormContext)

  return (
    <>
      <fieldset className="form__group">
        <legend className="form__legend" htmlFor="previous_resident">
          3b. Were you a previous PRHA resident?
        </legend>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="previous_resident"
              value="I am a previous PRHA resident"
            />
            Yes
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="previous_resident"
              value="I am not a previous PRHA resident"
            />
            No
          </label>
        </div>
        {errors.previous_resident && touched.previous_resident ? (
          <p className="form__error">{errors.previous_resident}</p>
        ) : null}
      </fieldset>
      {values.previous_resident === "I am a previous PRHA resident" && (
        <div className="form__group">
          <label className="form__label" htmlFor="previous_resident_address">
            Please give address:
          </label>
          <Field
            id="title"
            component="textarea"
            name="previous_resident_address"
            className="form__textarea"
          />
          {errors.previous_resident_address &&
          touched.previous_resident_address ? (
            <p className="form__error">{errors.previous_resident_address}</p>
          ) : null}
        </div>
      )}
    </>
  )
}

const FieldPet = () => {
  const { values, errors, touched } = useContext(HousingApplicationFormContext)

  return (
    <>
      <fieldset className="form__group">
        <legend className="form__label" htmlFor="pet">
          3c. Do you have a pet?
        </legend>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field type="radio" name="pet" value="I do have a pet" />
            Yes
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field type="radio" name="pet" value="I do not have a pet" />
            No
          </label>
        </div>
        {errors.pet && touched.pet ? (
          <p className="form__error">{errors.pet}</p>
        ) : null}
      </fieldset>
      {values.pet === "I do have a pet" && (
        <div className="form__group">
          <label className="form__label" htmlFor="pet_details">
            Please give further details:
          </label>
          <Field
            id="title"
            component="textarea"
            name="pet_details"
            className="form__textarea"
          />
          {errors.pet_details && touched.pet_details ? (
            <p className="form__error">{errors.pet_details}</p>
          ) : null}
        </div>
      )}
    </>
  )
}

const FieldHousingBenefit = () => {
  const { values, errors, touched } = useContext(HousingApplicationFormContext)

  return (
    <>
      <fieldset className="form__group">
        <legend className="form__label" htmlFor="housing_benefit">
          4. Do you claim Housing Benefit?
        </legend>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="housing_benefit"
              value="I do claim Housing Benefit"
            />
            Yes
          </label>
        </div>
        <div className="form--ha__radio form--ha__radio--inline">
          <label>
            <Field
              type="radio"
              name="housing_benefit"
              value="I do not claim Housing Benefit"
            />
            No
          </label>
        </div>
        {errors.housing_benefit && touched.housing_benefit ? (
          <p className="form__error">{errors.housing_benefit}</p>
        ) : null}
      </fieldset>
      {values.housing_benefit === "I do claim Housing Benefit" && (
        <div className="form__group">
          <label className="form__label" htmlFor="housing_benefit_details">
            Please give further details:
          </label>
          <Field
            id="title"
            component="textarea"
            name="housing_benefit_details"
            className="form__textarea"
          />
          {errors.housing_benefit_details && touched.housing_benefit_details ? (
            <p className="form__error">{errors.housing_benefit_details}</p>
          ) : null}
        </div>
      )}
    </>
  )
}

export default FieldGroupCurrentHousing
